import React from "react";
import Img from "../../assets/work1.jpg";
import Img2 from "../../assets/work2.jpg";
import "./portfolio.css";
import Img3 from "../../assets/work3.jpg";
import Img4 from "../../assets/work4.jpg";
import Img5 from "../../assets/sweetspot/home1.png";
import ImgHbm from "../../assets/hbm.png";
import Modal from "react-modal";
import { useState } from "react";
import Hamacoif from "./projects/Hamacoif";
import Olivia from "./projects/Olivia";
import Forcelearn from "./projects/Forcelearn";
import Gestion from "./projects/Gestion";
import Sweetspot from "./projects/Sweetspot";

const Portfolio = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModalOpen2, setIsModalOpen2] = useState(false);
  const [isModalOpen3, setIsModalOpen3] = useState(false);
  const [isModalOpen4, setIsModalOpen4] = useState(false);
  const [isModalOpen5, setIsModalOpen5] = useState(false);

  const handleDemoClick = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const handleDemoClick2 = () => {
    setIsModalOpen2(true);
  };

  const closeModal2 = () => {
    setIsModalOpen2(false);
  };

  const handleDemoClick3 = () => {
    setIsModalOpen3(true);
  };

  const closeModal3 = () => {
    setIsModalOpen3(false);
  };

  const handleDemoClick4 = () => {
    setIsModalOpen4(true);
  };

  const closeModal4 = () => {
    setIsModalOpen4(false);
  };

  const handleDemoClick5 = () => {
    setIsModalOpen5(true);
  }

  const closeModal5 = () => {
    setIsModalOpen5(false);
  }


  return (
    <section className="testimonial contaier section" id="portfolio">
      <h2 className="section__title">Portfolio</h2>
      <span className="section__subtitle">Most recent work</span>

      <div className="portfolio__container container grid">

      <card className="portfolio__content">          
          <div className="portfolio__data">
            <img src={ImgHbm} alt="" className="portfolio__img" />
            <h3 className="portfolio__title">HBM - Consulting</h3>
            <p className="portfolio__description">
            responsive website for an accounting firm
            </p>
            <p>visit the website : <a href="https://www.hbmconsulting.fr/" target="_blank">Hbmconsulting</a></p>
            {/* <button
              onClick={handleDemoClick5}
              className="button--small portfolio__button"
            >
              Demo
              <i className="uil uil-arrow-right button__icon"></i>
            </button> */}
          </div>
        </card>

        
        <card className="portfolio__content">          
          <div className="portfolio__data">
            <img src={Img5} alt="" className="portfolio__img" />
            <h3 className="portfolio__title">Feastandflavours - MERN</h3>
            <p className="portfolio__description">
            Responsive web application for a pastry shop called "SweetSpot".
            </p>
            <p>visit the website : <a href="https://www.feastandflavours.com/" target="_blank">feastandflavours</a></p>
            {/* <button
              onClick={handleDemoClick5}
              className="button--small portfolio__button"
            >
              Demo
              <i className="uil uil-arrow-right button__icon"></i>
            </button> */}
          </div>
        </card>
       
      </div>


      <div className="portfolio__container container grid">

      <card className="portfolio__content">
          <div className="portfolio__data">
            <img src={Img} alt="" className="portfolio__img" />
            <h3 className="portfolio__title">Hama Coiff</h3>
            <p className="portfolio__description">
              Responsive web application dedicated to hairdressers, offering
              customers the possibility order products and book services with
              ease.
            </p>
            <button
              onClick={handleDemoClick}
              className="button--small portfolio__button"
            >
              Demo
              <i className="uil uil-arrow-right button__icon"></i>
            </button>
          </div>
        </card>
        <card className="portfolio__content">
          <div className="portfolio__data">
            <img src={Img2} alt="" className="portfolio__img" />
            <h3 className="portfolio__title">e-commerce - Olivia</h3>
            <p className="portfolio__description">
              Develop a multi-vendor e-commerce web and mobile application
            </p>
            <button
              onClick={handleDemoClick2}
              className="button--small portfolio__button"
            >
              Demo
              <i className="uil uil-arrow-right button__icon"></i>
            </button>
          </div>
        </card>

       
      </div>

      <div className="portfolio__container container grid">

      <card className="portfolio__content">
          <div className="portfolio__data">
            <img src={Img3} alt="" className="portfolio__img" />
            <h3 className="portfolio__title">
              Management of employee activity
            </h3>
            <p className="portfolio__description">
              Development of a website for managing employee activity,
            </p>
            <button
              onClick={handleDemoClick4}
              className="button--small portfolio__button"
            >
              Demo
              <i className="uil uil-arrow-right button__icon"></i>
            </button>
          </div>
        </card>
        <card className="portfolio__content">
          <div className="portfolio__data">
            <img src={Img4} alt="" className="portfolio__img" />
            <h3 className="portfolio__title"> ForceLearn</h3>
            <p className="portfolio__description">
              Development of an educational web application - 'ForceLearn.'
            </p>
            <button
              onClick={handleDemoClick3}
              className="button--small portfolio__button"
            >
              Demo
              <i className="uil uil-arrow-right button__icon"></i>
            </button>
          </div>
        </card>
      </div>

      <Modal
        isOpen={isModalOpen}
        onRequestClose={closeModal}
        contentLabel="Image Modal"
        style={{
          content: {
            width: "80%",
            height: "70%",
            margin: "auto",
            // Set position to relative for the parent container
          },
        }}
      >
        <i
          onClick={closeModal}
          className="uil uil-times modal__close"
          style={{
            position: "absolute",
            top: "-2px", // Adjust the top position as needed
            right: "10px", // Adjust the right position as needed
            cursor: "pointer",
            fontSize: "1.5rem",
          }}
        ></i>
        {/* Add your images and styling for the modal content here */}
        <Hamacoif />
      </Modal>

      <Modal
        isOpen={isModalOpen2}
        onRequestClose={closeModal2}
        contentLabel="Image Modal"
        style={{
          content: {
            width: "80%",
            height: "70%",
            margin: "auto",
            // Set position to relative for the parent container
          },
        }}
      >
        <i
          onClick={closeModal2}
          className="uil uil-times modal__close"
          style={{
            position: "absolute",
            top: "-2px", // Adjust the top position as needed
            right: "10px", // Adjust the right position as needed
            cursor: "pointer",
            fontSize: "1.5rem",
          }}
        ></i>
        {/* Add your images and styling for the modal content here */}
        <Olivia />
      </Modal>

      <Modal
        isOpen={isModalOpen3}
        onRequestClose={closeModal3}
        contentLabel="Image Modal"
        style={{
          content: {
            width: "80%",
            height: "70%",
            margin: "auto",
            // Set position to relative for the parent container
          },
        }}
      >
        <i
          onClick={closeModal3}
          className="uil uil-times modal__close"
          style={{
            position: "absolute",
            top: "-2px", // Adjust the top position as needed
            right: "10px", // Adjust the right position as needed
            cursor: "pointer",
            fontSize: "1.5rem",
          }}
        ></i>
        {/* Add your images and styling for the modal content here */}
        <Forcelearn />
      </Modal>

      <Modal
        isOpen={isModalOpen4}
        onRequestClose={closeModal4}
        contentLabel="Image Modal"
        style={{
          content: {
            width: "80%",
            height: "70%",
            margin: "auto",

            // Set position to relative for the parent container
          },
        }}
      >
        <i
          onClick={closeModal4}
          className="uil uil-times modal__close"
          style={{
            position: "absolute",
            top: "-2px", // Adjust the top position as needed
            right: "10px", // Adjust the right position as needed
            cursor: "pointer",
            fontSize: "1.5rem",
          }}
        ></i>
        {/* Add your images and styling for the modal content here */}
        <Gestion />
      </Modal>

      <Modal
        isOpen={isModalOpen5}
        onRequestClose={closeModal5}
        contentLabel="Image Modal"
        style={{
          content: {
            width: "80%",
            height: "70%",
            margin: "auto",

            // Set position to relative for the parent container
          },
        }}
      >
        <i
          onClick={closeModal5}
          className="uil uil-times modal__close"
          style={{
            position: "absolute",
            top: "-2px", // Adjust the top position as needed
            right: "10px", // Adjust the right position as needed
            cursor: "pointer",
            fontSize: "1.5rem",
          }}
        ></i>
        {/* Add your images and styling for the modal content here */}
        <Sweetspot/>
      </Modal>
    </section>
  );
};

export default Portfolio;
