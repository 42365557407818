import logo from './logo.svg';
import './App.css';
import Header from './components/header/Header';
import Home from './components/home/home';
import About from './components/about/About';
import Skills from './components/skills/Skills';
import Qualification from './components/qualification/Qualification';
import Portfolio from './components/portfolio/Portfolio';
import Contact from './components/contact/Contact';
import Footer from './components/footer/Footer';
import ScrollUp from './components/scrollup/ScrollUp';




function App() {
  return (

   
    <>
      <Header />
      <Home />

     
      <About />
      <Skills />
      <Qualification />
      <Portfolio />
     
      <Contact />
      <Footer />
      <ScrollUp />
    </>

  

   
  );
}

export default App;
