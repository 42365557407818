import React from "react";

const Social = () => {
  return (
    <div className="home__social">
      <a href="https://www.linkedin.com/in/aminemkacher/" className="home__social-icon" target="_blank">
        <i className="uil uil-linkedin-alt"></i>
      </a>

      <a href="" className="home__social-icon" target="_blank">
        <i className="uil uil-github-alt"></i>
      </a>

     
    </div>
  );
};

export default Social;
